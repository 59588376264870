import {ApptSlot} from "@services/monolith/availability";

import useSWR from "swr";
import {pick} from "lodash";
import {ApptSlotsWorkerParams} from "../../../../workers/common/types";
import memoizee from "memoizee";
import queryString from "query-string-for-all";
import {NEXT_PUBLIC_WORKER_URL} from "../../../publicEnv";
import {withValidation} from "../../../utils/fetch/fetches";
import {jsonBody} from "../../../utils/fetch/responseBodies";
import batch from "../../../utils/fetch/batch";

const argsToPick: (keyof ApptSlotsWorkerParams)[] = [
  "selectedState",
  "specialtyId",
  "practiceId",
  "locationId",
  "doctorId",
  "reasonId",
  "days",
  "limit",
  "from",
  "to",
  "timezone",
  "patientId",
];

const getApptCachedUrl = memoizee(
  (args: ApptSlotsWorkerParams): string => {
    // Since the cloudflare cache key relies on URLs, it's important to make sure we don't send
    // _extra_ args becuase that would trigger a cache miss. Since typescript is structurally typed,
    // this can't be done reliably with tsc.
    const sanitizedArgs = pick(args, argsToPick);
    return queryString.stringifyUrl({
      url: `${NEXT_PUBLIC_WORKER_URL}/apptslots`,
      query: sanitizedArgs,
    });
  },
  {
    normalizer: args => JSON.stringify(args[0]), // because first arg is an object
  },
);

const fetcher = jsonBody<ApptSlot[]>(withValidation());
const batchFetcher = batch(fetcher);

/**
 * Fetch available slots. If an error occurs, return an empty list.
 * Note that the args passed here become the cache key, So callers are strongly encouraged to
 * balance the specificity of their args with the cache hit rate they need. More specific args
 * will reduce the cache hit rate, but return only the slots you really need. Less specific args
 * will have a higher hit rate, but then clients will need to do extra filtering to get rid
 * of the "extras."
 *
 * In the future, we should change how the cloudflare worker chooses its cache key and move
 * this filtering into the worker. That way we won't be using network bandwidth to send slots
 * that we don't plan to use.
 */
export async function tryFetchSlots(args: ApptSlotsWorkerParams): Promise<ApptSlot[]> {
  try {
    return await fetcher(getApptCachedUrl(args));
  } catch (e) {
    return [];
  }
}

export const useSlotsBatch = (args: ApptSlotsWorkerParams[]) => {
  return useSWR<ApptSlot[][]>(args.length === 0 ? null : args.map(getApptCachedUrl), batchFetcher, {
    revalidateOnFocus: false,
  });
};
