/**
 * Given a fetch-like function, return a new one which takes an array of requests
 * and fetches them in parallel.
 */
export default function batch<T>(
  f: (...args: Parameters<typeof fetch>) => Promise<T>,
): (inputs: (RequestInfo | URL)[], init: RequestInit) => Promise<T[]> {
  return (inputs: (RequestInfo | URL)[], init?: RequestInit) => {
    return Promise.all(inputs.map(input => f(input, init)));
  };
}
